
// import common from './routes/common';
// import Router from './util/Router';

// const routes = {
//   common,
// };

// // Load Events
// jQuery(document).ready(() => new Router(routes).loadEvents());
